// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-about-js": () => import("./../../../src/templates/About.js" /* webpackChunkName: "component---src-templates-about-js" */),
  "component---src-templates-archive-news-js": () => import("./../../../src/templates/ArchiveNews.js" /* webpackChunkName: "component---src-templates-archive-news-js" */),
  "component---src-templates-archive-positions-js": () => import("./../../../src/templates/ArchivePositions.js" /* webpackChunkName: "component---src-templates-archive-positions-js" */),
  "component---src-templates-archive-works-js": () => import("./../../../src/templates/ArchiveWorks.js" /* webpackChunkName: "component---src-templates-archive-works-js" */),
  "component---src-templates-careers-js": () => import("./../../../src/templates/Careers.js" /* webpackChunkName: "component---src-templates-careers-js" */),
  "component---src-templates-contact-js": () => import("./../../../src/templates/Contact.js" /* webpackChunkName: "component---src-templates-contact-js" */),
  "component---src-templates-home-js": () => import("./../../../src/templates/home.js" /* webpackChunkName: "component---src-templates-home-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-positions-single-js": () => import("./../../../src/templates/PositionsSingle.js" /* webpackChunkName: "component---src-templates-positions-single-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-sap-js": () => import("./../../../src/templates/SAP.js" /* webpackChunkName: "component---src-templates-sap-js" */),
  "component---src-templates-thank-you-js": () => import("./../../../src/templates/ThankYou.js" /* webpackChunkName: "component---src-templates-thank-you-js" */),
  "component---src-templates-wp-vip-js": () => import("./../../../src/templates/WpVip.js" /* webpackChunkName: "component---src-templates-wp-vip-js" */)
}

